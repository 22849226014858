<template>
  <div class="org-role">
    <div class="role-list">
      <div class="role-item" v-for="item in dataList" :key="item.id">
        <div class="role-name">{{item.roleName}}</div>
        <div class="btns">
          <div class="btn-item" :class="{'btn-item-b': $authFunsProxy.delRole}" @click="auth(item)" v-if="$authFunsProxy.roleAuth">权限</div>
          <div class="btn-item" @click="del(item)" v-if="$authFunsProxy.delRole">删除</div>
        </div>
      </div>
      <div class="role-item" @click="add" v-if="$authFunsProxy.addRole">
        + 添加
      </div>
    </div>
    <fm-form-dialog
      form-title="新增职务"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      label-alone
      :mask-closable="false"
      label-align="left"
      form-width="400px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
    <fm-modal v-model="modalAuth" width="500px" theme="mh-blackt" title="菜单赋权">
      <div style="height: 60vh;width: 100%;overflow-y: auto;">
        <MenuAuth ref="menuAuth" :fun-ids="chooseData.funIds" :menu-ids="chooseData.menuIds" v-if="modalAuth"></MenuAuth>
      </div>
      <div class="btns2">
        <fm-btn @click="saveAuth" style="margin-right: 50px;">确定</fm-btn>
        <fm-btn @click="modalAuth = false">取消</fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import {
  userOrgRoleRequest
} from '@/api'

import MenuAuth from '../../sys/menu/auth'

export default {
  components: {
    MenuAuth
  },
  props: {
    orgId: {
      type: Number
    }
  },
  data () {
    return {
      dataList: [],
      chooseData: null,
      openDialog: false,
      modalAuth: false
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'select',
        label: '职务',
        key: 'roleId',
        selectDatas: this.$store.getters.roleList.filter(v => v.data.roleType === 'position'),
        check: {
          required: true
        }
      }]
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async saveAuth () {
      let data = this.$refs.menuAuth.getChecked()
      await userOrgRoleRequest.update(this.chooseData.id, Object.assign({}, this.chooseData, data))
      this.modalAuth = false
      this.loadData()
    },
    auth (item) {
      this.chooseData = item
      this.modalAuth = true
    },
    async formSubmit (data, r) {
      await userOrgRoleRequest.add({
        roleId: data.roleId,
        orgId: this.orgId
      })
      this.loadData()
      r()
      this.openDialog = false
    },
    async add () {
      this.openDialog = true
    },
    async del (item) {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该组织架构下的 ' + item.roleName + ' 职务吗?'})
      if (result) {
        userOrgRoleRequest.del(item.id).then(() => {
          this.$notify({
            title: '系统提示',
            message: '该组织架构下的 ' + item.roleName + ' 职务已删除',
            type: 'info'
          })
          this.loadData()
        })
      }
    },
    async loadData () {
      let datas = await userOrgRoleRequest.get({
        type: 'org_role',
        orgId: this.orgId,
        needAuth: 1
      })
      datas.forEach(v => {
        v.funIds = v.funs.map(v1 => v1.id)
        v.menuIds = []
        this.getMenuIds(v.menus, v.menuIds)
      })
      this.dataList = datas
    },
    getMenuIds (menus, ids) {
      menus.forEach(v => {
        ids.push(v.id)
        if (v.children) {
          this.getMenuIds(v.children, ids)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.role-list {
    display: flex;
    flex-wrap: wrap;
  }
  .role-item {
    cursor: pointer;
    min-width: 100px;
    padding: 10px 10px 20px;
    position: relative;
    margin: 0 20px 20px 0;
    height: 80px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px  rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .role-item:hover {
    box-shadow: 2px 2px 4px  rgba(0,0,0,0.2);
    .btns {
      display: flex;
    }
  }
  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    border-top: 1px solid rgba(0,0,0,0.05);
    .btn-item {
      flex: 1;
      line-height: 22px;
      text-align: center;
      font-size: 12px;
    }
    .btn-item-b {
      border-right: 1px solid rgba(0,0,0,0.05);
    }
  }
  .btns2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>