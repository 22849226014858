<template>
  <fm-tree placeholder="菜单权限" multiple :nodes="nodes" search is-checked ref="tree"></fm-tree>
</template>

<script>

export default {
  props: {
    funIds: {
      type: Array,
      default: () => {
        return []
      }
    },
    menuIds: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    nodes () {
      const sysMenus = this.$store.getters.menuList.map(v => v.data)
      let funFn = (funs) => {
        return funs.map(fun => {
          return {
            title: fun.name,
            data: Object.assign({isFun: true}, fun),
            checked: this.funIds.includes(fun.id)
          }
        })
      }

      let fn = (menu) => {
        let clientName = '其他'
        if (menu.clientType === 'web') {
          clientName = '电脑'
        } else if (menu.clientType === 'wap') {
          clientName = '手机端'
        } else if (menu.clientType === 'pda') {
          clientName = 'PDA设备'
        } else if (menu.clientType === 'app') {
          clientName = 'APP'
        }
        return {
          title: menu.name + ' - ' + menu.sys + ' - ' + clientName,
          data: Object.assign({isMenu: true}, menu),
          checked: this.menuIds.includes(menu.id)
        }
      }

      let result = sysMenus.filter(v => v.pid === 0).map(fn)
      ;(function each (nodes) {
        nodes.forEach(node => {
          if (node.data.funs && node.data.funs.length) {
            node.children = funFn(node.data.funs)
          } else {
            node.children = sysMenus.filter(v => v.pid === node.data.id).map(fn)
            each(node.children)
          }
        })
      })(result)
      return result
    }
  },
  created () {
    this.$store.dispatch('loadMenuList')
  },
  methods: {
    getChecked () {
      const data = [...this.$refs.tree.checkedList.values()]
      let menuIds = data.filter(({data}) => data.data.isMenu).map(({data}) => data.data.id)
      let funIds = data.filter(({data}) => data.data.isFun).map(({data}) => data.data.id)
      return {
        menuIds, funIds
      }
    }
  }
}
</script>
